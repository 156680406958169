import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { Account } from '../../api/account/types'
import { getAccountActions, signOut } from './account.actions'

export interface State {
	loading: boolean
	data: Account | undefined
	lastUpdated: number
}
export const defaultState: State = {
	loading: false,
	data: undefined,
	lastUpdated: 0,
}

export const reducer = reducerWithInitialState(defaultState)
	.case(getAccountActions.started, (state) => ({
		...state,
		loading: true,
	}))
	.case(getAccountActions.done, (state, payload) => ({
		...state,
		loading: false,
		data: payload.result,
		lastUpdated: Date.now(),
	}))
	.case(getAccountActions.failed, (state) => ({
		...state,
		loading: false,
	}))
	.case(signOut, () => defaultState)
	.default((state) => state)
