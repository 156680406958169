import useSWR from 'swr'
import { getNotifications } from '../api/account/account'

export const useNotifications = () => {
	const { data, mutate } = useSWR('/v1/notifications', () => getNotifications(), {
		refreshInterval: 5000,
	})

	return {
		data,
		mutate,
	}
}
