import { FC } from 'react'
import { Account } from '../../../api/account/types'
import { useReferralLinks } from '../../../hooks/useReferralLinks'
import { SweetAlert } from '../../../utilities/sweetAlert'

interface ReferralLinksProps {
	account: Account
}
const ReferralLinks: FC<ReferralLinksProps> = ({ account }) => {
	const { referralLinks } = useReferralLinks()

	const copyLink = async (refCode: string) => {
		try {
			await window.navigator.clipboard.writeText(`${window.location.origin}/sign-in?ref=${refCode}`)

			SweetAlert.success('คัดลอกลิงก์แนะนำเพื่อนสำเร็จ')
		} catch ({ message }: any) {
			SweetAlert.error(message as string)
		}
	}
	const renderTableContent = () => {
		if (referralLinks.links.length <= 0) {
			return (
				<tr>
					<td colSpan={3} className="text-center">
						คุณไม่มีลิงก์แนะนำเพื่อน
					</td>
				</tr>
			)
		}
		return referralLinks.links.map((link) => (
			<tr key={`referral-link-${link.code}`}>
				<td>{link.code}</td>
				<td>
					<b>{link.uplineCommission}</b>% / <b>{link.downlineDiscount}</b>%
				</td>
				<td>
					<button type="button" className="btn btn-warning btn-sm" onClick={() => copyLink(link.code)}>
						<i className="fad fa-copy" /> &nbsp; คัดลอกลิงก์
					</button>
				</td>
			</tr>
		))
	}

	if (referralLinks.loading && referralLinks.lastUpdated === 0) {
		return null
	}
	return (
		<section className="page-section">
			<div className="page-section-header">
				<h3>ลิงก์แนะนำเพื่อนของคุณ</h3>
				<h6>
					คุณสามารถสร้างลิงก์แนะนำเพื่อนได้สูงสุด&nbsp;
					<b>
						{referralLinks.links.length}/{account.limits.referralLinks}
					</b>
					&nbsp;ลิงก์
				</h6>
			</div>

			<div className="page-section-content">
				<div className="table-responsive">
					<table className="table">
						<thead className="table-light">
							<tr>
								<th>Referral Code</th>
								<th>คุณได้รับ / เพื่อนได้รับ</th>
								<th></th>
							</tr>
						</thead>
						<tbody>{renderTableContent()}</tbody>
					</table>
				</div>
			</div>
		</section>
	)
}

export default ReferralLinks
