import React, { FC } from 'react'
import { generateQRCodeLink } from '../../../utilities/qrcode'

interface BankTopupQRCodeDialogProps {
	data: string
	onClose: () => void
}
const BankTopupQRCodeDialog: FC<BankTopupQRCodeDialogProps> = ({ data, onClose }) => (
	<div className="dialog referral-create-link-dialog">
		<div className="dialog-wrapper">
			<div className="dialog-header">
				<h3>เติมเงินผ่านธนาคาร</h3>
				<h6>
					สแกน QR Code ด้วย<u>แอพธนาคาร</u>เพื่อเติมเงิน
				</h6>
			</div>
			<div className="dialog-content bank-topup-dialog">
				<div className="bank-topup-qr-code">
					<img src={generateQRCodeLink(data)} alt="QR Code" />
				</div>
				<div className="bank-topup-suggestion">
					* กรุณาทำรายการเติมเงินภายใน <span>1</span> ชั่วโมง *
				</div>
				<button type="button" className="btn btn-warning mt-4" onClick={() => onClose()}>
					ยกเลิก
				</button>
			</div>
		</div>
	</div>
)

export default BankTopupQRCodeDialog
