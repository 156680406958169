import { useStripe } from '@stripe/react-stripe-js'
import { useFormik } from 'formik'
import { Fragment, useState } from 'react'
import { generateBankQRCode } from '../../../api/topup/topup'
import { useAccount } from '../../../hooks/useAccount'
import { SweetAlert } from '../../../utilities/sweetAlert'
import BankTopupQRCodeDialog from './BankTopupQRCodeDialog'

const BankTopup = () => {
	const stripe = useStripe()
	const user = useAccount()

	const [topupQRCode, setTopupQRCode] = useState('')
	const { values, isSubmitting, handleChange, handleSubmit } = useFormik({
		initialValues: {
			amount: '100',
		},
		onSubmit: async (values) => {
			try {
				const res = await generateBankQRCode({ amount: parseFloat(values.amount) })

				if (res.clientSecret && stripe) {
					const payment = await stripe.confirmPromptPayPayment(
						res.clientSecret,
						{
							payment_method: {
								billing_details: {
									email: user.account.data?.email || `user.${user.account.data?.id}@uplive-th.com`,
								},
							},
						},
						{
							handleActions: false,
						}
					)

					if (payment && payment.paymentIntent?.next_action?.type === 'promptpay_display_qr_code') {
						setTopupQRCode((payment.paymentIntent.next_action as any).promptpay_display_qr_code.data)
					} else {
						SweetAlert.error('ไม่สามารถดำเนินการได้ในขณะนี้ กรุณาติดต่อผู้ดูแลระบบ')
					}
				} else if (res.data) {
					setTopupQRCode(res.data)
				}
			} catch ({ message }: any) {
				SweetAlert.error(message as string)
			}
		},
	})

	return (
		<Fragment>
			<div className="card">
				<div className="card-header">
					เติมเงินผ่านธนาคาร <small>(QR Code)</small>
				</div>
				<div className="card-body">
					<form autoComplete="off" onSubmit={handleSubmit}>
						<div className="mb-3">
							<label htmlFor="amount" className="form-label">
								จำนวนเงิน
							</label>
							<input
								type="number"
								name="amount"
								id="amount"
								className="form-control"
								placeholder="จำนวนเงิน"
								min={100}
								defaultValue={values.amount}
								required
								onChange={handleChange}
							/>
						</div>
						<button type="submit" className="btn btn-warning" disabled={isSubmitting}>
							เติมเงิน
						</button>
					</form>
				</div>
			</div>

			{topupQRCode && <BankTopupQRCodeDialog data={topupQRCode} onClose={() => setTopupQRCode('')} />}
		</Fragment>
	)
}

export default BankTopup
