import { request } from '..'
import { GenerateBankQRCode, GenerateBankQRCodePayload, TopupTx } from './types'

export const getTopupTransactions = () =>
	request<TopupTx[]>({
		method: 'GET',
		url: '/topup/transactions',
	})

export const generateBankQRCode = (payload: GenerateBankQRCodePayload) =>
	request<GenerateBankQRCode>({
		method: 'POST',
		url: '/topup/bank-qr',
		data: payload,
	})
