import useSWR from 'swr'
import { getTopupTransactions } from '../api/topup/topup'

export const useTopupTransactions = () => {
	const { data } = useSWR('/v1/topup/transactions', () => getTopupTransactions(), {
		refreshInterval: 15000,
	})

	return {
		data,
	}
}
