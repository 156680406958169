import React, { FC } from 'react'
import { format } from '../../../utilities/number'

interface TotalCommissionProps {
	data: number
}
const TotalCommission: FC<TotalCommissionProps> = ({ data }) => (
	<div className="card">
		<div className="card-body bg-light">
			<div className="text-center mb-3">
				<i className="fad fa-sack-dollar" /> &nbsp; ยอดคอมมิชชั่น
			</div>
			<div className="referral-summary">
				<h3>{format(data, 2)}</h3>
			</div>
		</div>
	</div>
)

export default TotalCommission
