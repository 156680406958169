import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import firebase from 'firebase/app'
import 'firebase/auth'
import { getStorage } from '../utilities/storage'

export const baseRequest = axios.create({
	baseURL: process.env.REACT_APP_API_ENDPOINT || 'http://localhost:8081/v1',
})
export const request = <T>(config: AxiosRequestConfig): Promise<T> =>
	baseRequest(config)
		.then((res) => res.data)
		.catch((err: AxiosError) => {
			if (err.response?.data) {
				return Promise.reject(err.response.data)
			}
			return Promise.reject({
				message: err.message,
			})
		})

baseRequest.interceptors.request.use(async (config) => {
	try {
		const token = await firebase.auth().currentUser?.getIdToken()
		const referralCode = getStorage('referralCode')

		return {
			...config,
			headers: {
				...config.headers,
				...(token && { Authorization: `Bearer ${token}` }),
				...(referralCode && { 'X-Referral-Code': referralCode }),
			},
		}
	} catch (error) {
		console.error(error)

		return config
	}
})
