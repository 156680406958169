import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../store'
import { getReferralLinks } from '../store/referralLinks/referralLinks.requests'
import { ThunkDispatch } from '../store/types'

export const useReferralLinks = () => {
	const dispatch: ThunkDispatch = useDispatch()
	const referralLinks = useSelector((state: ApplicationState) => state.referralLinks)

	const requestReferralLinks = () => dispatch(getReferralLinks())

	return {
		referralLinks,
		requestReferralLinks,
	}
}
