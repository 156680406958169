import firebase from 'firebase/app'
import 'firebase/auth'
import { useFormik } from 'formik'
import { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { cache } from 'swr'
import { translateFirebaseError } from '../../utilities/firebase'
import { SweetAlert } from '../../utilities/sweetAlert'

const SignIn = () => {
	const history = useHistory()
	const { isSubmitting, handleChange, handleSubmit } = useFormik({
		initialValues: {
			email: '',
			password: '',
		},
		onSubmit: async ({ email, password }) => {
			try {
				await firebase.auth().signInWithEmailAndPassword(email, password)

				cache.clear()
				SweetAlert.success('เข้าสู่ระบบสำเร็จ').then(() => {
					history.push('/dashboard')
				})
			} catch (error) {
				SweetAlert.error(translateFirebaseError(error as firebase.FirebaseError))
			}
		},
	})

	const [googleLoading, setGoogleLoading] = useState(false)
	const loginWithGoogle = () => {
		setGoogleLoading(true)

		const provider = new firebase.auth.GoogleAuthProvider()
		firebase
			.auth()
			.signInWithPopup(provider)
			.then(() => {
				SweetAlert.success('เข้าสู่ระบบสำเร็จ').then(() => {
					history.push('/dashboard')
				})
			})
			.finally(() => {
				setGoogleLoading(false)
			})
	}
	// useEffect(() => {
	// 	firebase
	// 		.auth()
	// 		.getRedirectResult()
	// 		.then((result) => {
	// 			if (result.user) {
	// 				cache.clear()
	// 				SweetAlert.success('เข้าสู่ระบบสำเร็จ').then(() => {
	// 					history.push('/dashboard')
	// 				})
	// 			} else {
	// 				setGoogleLoading(false)
	// 			}
	// 		})
	// }, [history])

	return (
		<div className="auth-container">
			<div className="auth-heading">
				<i className="fad fa-podcast fa-3x" />

				<h5>UPLIVE-TH</h5>
				<h6>กรุณาเข้าสู่ระบบเพื่อดำเนินการต่อ</h6>
			</div>

			<form onSubmit={handleSubmit}>
				<div className="form-floating">
					<input
						type="email"
						name="email"
						id="email"
						className="form-control"
						placeholder="อีเมล"
						required
						onChange={handleChange}
					/>
					<label htmlFor="email">อีเมล</label>
				</div>
				<div className="form-floating">
					<input
						type="password"
						name="password"
						id="password"
						className="form-control"
						placeholder="รหัสผ่าน"
						required
						onChange={handleChange}
					/>
					<label htmlFor="password">รหัสผ่าน</label>
				</div>

				<button type="submit" className="btn btn-warning" disabled={isSubmitting}>
					เข้าสู่ระบบ
				</button>
				<div className="auth-suggestion">
					ยังไม่มีบัญชีผู้ใช้งาน?&nbsp;
					<Link to="/sign-up" className="link">
						สมัครสมาชิก
					</Link>
				</div>
			</form>

			<button
				type="submit"
				className="btn btn-dark mt-3"
				disabled={googleLoading}
				onClick={loginWithGoogle}
			>
				<i className="fab fa-google" /> &nbsp;เข้าสู่ระบบด้วย Google
			</button>
		</div>
	)
}

export default SignIn
