import { request } from '..'
import { APIResponse } from '../types'
import { CreateReferralLinkPayload, ReferralLink } from './types'

export const getReferralLinks = () =>
	request<ReferralLink[]>({
		method: 'GET',
		url: '/referral/links',
	})

export const createReferralLink = (payload: CreateReferralLinkPayload) =>
	request<APIResponse>({
		method: 'POST',
		url: '/referral/links',
		data: payload,
	})
