import React, { FC } from 'react'
import { format } from '../../../utilities/number'

interface TotalRegisteredUsersProps {
	data: number
}
const TotalRegisteredUsers: FC<TotalRegisteredUsersProps> = ({ data }) => (
	<div className="card">
		<div className="card-body bg-light">
			<div className="text-center mb-3">
				<i className="fad fa-user-robot" /> &nbsp; จำนวนผู้สมัครสมาชิก
			</div>
			<div className="referral-summary">
				<h3>{format(data)}</h3>
			</div>
		</div>
	</div>
)

export default TotalRegisteredUsers
