import { AsyncActionCreators } from 'typescript-fsa'
import { ThunkActionCreator } from './types'

export const createRequestThunk =
	<Req extends any, Res, Err>(
		actions: AsyncActionCreators<Req, Res, Err>,
		request: (params: Req) => Promise<Res>
	): ThunkActionCreator<Promise<Res>, Req> =>
	(params: Req) =>
	async (dispatch) => {
		const { started, done, failed } = actions

		dispatch(started(params))
		try {
			const result = await request(params)

			dispatch(done({ result, params }))
			return result
		} catch (error: any) {
			dispatch(failed({ error, params }))
			return Promise.reject(error)
		}
	}
