import React, { FC } from 'react'
import { format } from '../../../utilities/number'

interface CurrentCommissionProps {
	data: number
}
const CurrentCommission: FC<CurrentCommissionProps> = ({ data }) => (
	<div className="card">
		<div className="card-body bg-light">
			<div className="text-center mb-3">
				<i className="fad fa-coins" /> &nbsp; ยอดคงเหลือ
			</div>
			<div className="referral-summary">
				<h3>{format(data, 2)}</h3>
			</div>
		</div>
	</div>
)

export default CurrentCommission
