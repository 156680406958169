import React, { lazy, Suspense } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import LoadingPage from './components/LoadingPage'
import Refferal from './features/Referral'
import SignIn from './features/SignIn'
import SignUp from './features/SignUp'
import Subscriptions from './features/Subscriptions'
import Topup from './features/Topup'
import AppLayout from './layouts/AppLayout'
import AuthMiddleware from './middlewares/AuthMiddleware'
import ReferralMiddleware from './middlewares/ReferralMiddleware'
import store from './store'

const Dashborard = lazy(() => import('./features/Dashboard'))

const MainContent = () => {
	return (
		<AuthMiddleware>
			<Suspense fallback={<LoadingPage />}>
				<AppLayout>
					<Switch>
						<Route path="/dashboard" component={Dashborard} />
						<Route path="/subscriptions" component={Subscriptions} />
						<Route path="/topup" component={Topup} />
						<Route path="/referral" component={Refferal} />

						<Redirect to="/dashboard" />
					</Switch>
				</AppLayout>
			</Suspense>
		</AuthMiddleware>
	)
}

const App = () => {
	return (
		<Provider store={store}>
			<Router>
				<ReferralMiddleware>
					<Switch>
						<Route path="/sign-in" component={SignIn} />
						<Route path="/sign-up" component={SignUp} />

						<MainContent />
					</Switch>
				</ReferralMiddleware>
			</Router>
		</Provider>
	)
}

export default App
