import { FC, Fragment, useCallback, useEffect } from 'react'
import { Notification } from '../../api/account/types'
import { useAccount } from '../../hooks/useAccount'
import { useNotifications } from '../../hooks/useNotifications'
import { SweetAlert } from '../../utilities/sweetAlert'

const NotificationsMiddleware: FC<any> = ({ children }) => {
	const { requestAccount } = useAccount()

	const { data, mutate: mutateNotifications } = useNotifications()
	const fireNotifications = useCallback(
		async (notifications: Notification[]) => {
			const actions: string[] = []
			for (let index in notifications) {
				if (!notifications[index]) {
					continue
				}

				const notice = notifications[index]
				if (notice.type === 'error') {
					await SweetAlert.error(notice.message)
				} else if (notice.type === 'success') {
					await SweetAlert.success(notice.message)
				}

				for (const action of notice.actions) {
					if (!actions.includes(action)) {
						actions.push(action)
					}
				}
			}

			if (actions.includes('requestAccount')) {
				requestAccount()
			}
		},
		[requestAccount]
	)
	useEffect(() => {
		if (data && data.length > 0) {
			mutateNotifications([], false)

			fireNotifications(data)
		}
	}, [data, mutateNotifications, fireNotifications])

	return <Fragment>{children}</Fragment>
}

export default NotificationsMiddleware
