import qs from 'qs'
import { FC, Fragment, useEffect } from 'react'
import { useHistory } from 'react-router'
import { setStorage } from '../../utilities/storage'

const ReferralMiddleware: FC<any> = ({ children }) => {
	const history = useHistory()
	useEffect(() => {
		const query = qs.parse(history.location.search.substr(1))

		if (query.ref) {
			setStorage('referralCode', query.ref.toString())
		}
	}, [history])

	return <Fragment>{children}</Fragment>
}

export default ReferralMiddleware
