import clsx from 'clsx'
import { FC, useEffect, useState } from 'react'
import { createReferralLink as _createReferralLink } from '../../../api/referralLinks/referralLinks'
import { useAccount } from '../../../hooks/useAccount'
import { useReferralLinks } from '../../../hooks/useReferralLinks'
import { SweetAlert } from '../../../utilities/sweetAlert'

interface CreateReferralLinkDialogProps {
	open: boolean

	onClose: () => void
}
const CreateReferralLinkDialog: FC<CreateReferralLinkDialogProps> = ({ open, onClose }) => {
	const { account } = useAccount()
	const { requestReferralLinks } = useReferralLinks()

	const [loading, setLoading] = useState(false)
	const [downlineDiscount, setDownlineDiscount] = useState(0)
	const createReferralLink = async () => {
		setLoading(true)

		try {
			const res = await _createReferralLink({ downlineDiscount })

			setLoading(false)
			SweetAlert.success(res.message).then(() => {
				requestReferralLinks()

				onClose()
			})
		} catch ({ message }: any) {
			setLoading(false)
			SweetAlert.error(message as string).then(() => onClose())
		}
	}

	// reset to default on close dialog
	useEffect(() => {
		if (!open) {
			setDownlineDiscount(0)
		}
	}, [open])

	if (!account.data) {
		return null
	}

	const commissionRate = account.data.referral.commissionRate
	const donwlineRates = account.data.referral.downlineRates

	return (
		<div className="dialog referral-create-link-dialog">
			<div className="dialog-wrapper">
				<div className="dialog-header">
					<h3>สร้างลิงก์แนะนำเพื่อน</h3>
					<h6>
						คุณจะได้รับค่าคอมมิชชั่นสูงสุด <b>{commissionRate}%</b>
					</h6>
				</div>
				<div className="dialog-content">
					<div className="referral-rates">
						<div className="row">
							<div className="col-6">
								<div className="referral-rates-item">
									<h3>คุณได้รับ</h3>
									<h6>{commissionRate - downlineDiscount}%</h6>
								</div>
							</div>
							<div className="col-6">
								<div className="referral-rates-item">
									<h3>เพื่อนได้รับ</h3>
									<h6>{downlineDiscount}%</h6>
								</div>
							</div>
						</div>
					</div>

					<div className="referral-rates-selector">
						<div className="referral-selector-text">เลือกส่วนแบ่งสำหรับเพื่อน</div>
						<div className="referral-rates-options">
							{donwlineRates.map((rate) => (
								<div
									key={`downline-rate-${rate}`}
									className={clsx('referral-rates-options-item', {
										active: rate === downlineDiscount,
										disabled: rate > commissionRate / 2,
									})}
									onClick={() => rate <= commissionRate / 2 && setDownlineDiscount(rate)}
								>
									{rate}%
								</div>
							))}
						</div>
					</div>

					<div className="row mt-3">
						<div className="col">
							<button
								type="button"
								className="btn btn-warning"
								disabled={loading}
								onClick={createReferralLink}
							>
								ยืนยัน
							</button>
						</div>
						<div className="col">
							<button type="button" className="btn btn-light" onClick={() => !loading && onClose()}>
								ยกเลิก
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default CreateReferralLinkDialog
