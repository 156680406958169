import firebase from 'firebase/app'

const firebaseErrorTH: Record<string, string> = {
	'auth/user-not-found': 'อีเมลหรือรหัสผ่านไม่ถูกต้อง',
	'auth/weak-password': 'กรุณาใช้รหัสผ่านที่คาดเดาได้ยาก เพื่อป้องกันการถูกใช้งานบัญชี',
	'auth/invalid-email': 'อีเมลไม่ถูกต้อง',
	'auth/email-already-in-use': 'อีเมลถูกใช้งานไปแล้ว',
}
export const translateFirebaseError = (error: firebase.FirebaseError): string => {
	if (firebaseErrorTH[error.code]) {
		return firebaseErrorTH[error.code]
	}
	return error.message
}
