import useSWR from 'swr'
import { getReferralWithdraws } from '../api/referral/refferal'

export const useReferralWithdraws = () => {
	const { data: referralWithdraws, mutate: mutateReferralWithdraws } = useSWR('/v1/referral/withdraws', () =>
		getReferralWithdraws()
	)

	return {
		referralWithdraws,
		mutateReferralWithdraws,
	}
}
