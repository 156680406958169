import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { ReferralSummary } from '../../api/referral/types'
import { signOut } from '../account/account.actions'
import { getReferralSummaryActions } from './referral.actions'

export interface State {
	loading: boolean
	summary: ReferralSummary | null
	lastUpdated: number
}
export const defaultState: State = {
	loading: false,
	summary: null,
	lastUpdated: 0,
}

export const reducer = reducerWithInitialState(defaultState)
	.case(getReferralSummaryActions.started, (state) => ({
		...state,
		loading: true,
	}))
	.case(getReferralSummaryActions.done, (state, payload) => ({
		...state,
		loading: false,
		summary: payload.result,
		lastUpdated: Date.now(),
	}))
	.case(getReferralSummaryActions.failed, (state) => ({
		...state,
		loading: false,
	}))
	.case(signOut, () => defaultState)
	.default((state) => state)
