import useSWR from 'swr'
import {
	getSubscriptionOptions,
	getSubscriptions,
	getSubscriptionsTasks,
	getSubscriptionsUsage,
} from '../api/subscription/subscription'

export const useSubscriptions = () => {
	const { data: subscriptions, mutate: mutateSubscriptions } = useSWR('/v1/subscriptions', () => getSubscriptions())
	const { data: subscriptionOptions } = useSWR('/v1/subscriptions/options', () => getSubscriptionOptions())
	const { data: subscriptionsTasks } = useSWR('/v1/subscriptions/tasks', () => getSubscriptionsTasks(), {
		refreshInterval: 15000,
	})
	const { data: subscriptionsUsage } = useSWR('/v1/subscriptions/usage', () => getSubscriptionsUsage(), {
		refreshInterval: 15000,
	})

	return {
		subscriptions,
		mutateSubscriptions,

		subscriptionOptions,
		subscriptionsTasks,
		subscriptionsUsage,
	}
}
