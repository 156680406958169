import 'bootstrap'
import 'firebase/analytics'
import firebase from 'firebase/app'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './app.scss'
import reportWebVitals from './reportWebVitals'

firebase.initializeApp({
	apiKey: 'AIzaSyAxuJb7HI1kynixuJwCE2AvkLhTdpaa3bU',
	authDomain: 'uplive-th.firebaseapp.com',
	projectId: 'uplive-th',
	storageBucket: 'uplive-th.appspot.com',
	messagingSenderId: '687052585330',
	appId: '1:687052585330:web:0960a9c4d34e90fa8385eb',
	measurementId: 'G-H87Y8LM4NW',
})

// initilize analytics on production
if (process.env.NODE_ENV === 'production') {
	firebase.analytics()
}

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
