import Swal from 'sweetalert2'

export const SweetAlert = {
	success: (message: string) => {
		return Swal.fire({
			icon: 'success',
			title: 'สำเร็จ',
			text: message,
			confirmButtonText: 'ตกลง',
		})
	},
	error: (message: string) => {
		return Swal.fire({
			icon: 'error',
			title: 'เกิดข้อผิดพลาด',
			text: message,
			confirmButtonText: 'ตกลง',
		})
	},
}
