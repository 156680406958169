import firebase from 'firebase/app'
import 'firebase/auth'
import { useFormik } from 'formik'
import { Link, useHistory } from 'react-router-dom'
import { translateFirebaseError } from '../../utilities/firebase'
import { SweetAlert } from '../../utilities/sweetAlert'

const SignUp = () => {
	const history = useHistory()
	const { isSubmitting, handleChange, handleSubmit } = useFormik({
		initialValues: {
			email: '',
			password: '',
			confirmPassword: '',
		},
		onSubmit: async ({ email, password, confirmPassword }) => {
			if (password.length < 6 || confirmPassword.length < 6) {
				SweetAlert.error('รหัสผ่านต้องมีความยาวมากกว่า 6 ตัวอักษร')
				return false
			}
			if (password !== confirmPassword) {
				SweetAlert.error('รหัสผ่านทั้งสองช่องไม่ตรงกัน')
				return false
			}

			try {
				await firebase.auth().createUserWithEmailAndPassword(email, password)

				SweetAlert.success('สมัครสมาชิกสำเร็จ').then(() => {
					history.push('/dashboard')
				})
			} catch (error: any) {
				SweetAlert.error(translateFirebaseError(error))
			}
		},
	})

	return (
		<div className="auth-container">
			<div className="auth-heading">
				<i className="fad fa-podcast fa-3x" />

				<h5>UPLIVE-TH</h5>
				<h6>กรุณาสมัครสมาชิกเพื่อดำเนินการต่อ</h6>
			</div>

			<form autoComplete="off" onSubmit={handleSubmit}>
				<div className="form-floating">
					<input
						type="email"
						name="email"
						id="email"
						className="form-control"
						placeholder="อีเมล"
						required
						onChange={handleChange}
					/>
					<label htmlFor="email">อีเมล</label>
				</div>
				<div className="form-floating">
					<input
						type="password"
						name="password"
						id="password"
						className="form-control"
						placeholder="รหัสผ่าน"
						required
						onChange={handleChange}
					/>
					<label htmlFor="password">รหัสผ่าน</label>
				</div>
				<div className="form-floating">
					<input
						type="password"
						name="confirmPassword"
						id="confirmPassword"
						className="form-control"
						placeholder="ยืนยันรหัสผ่าน"
						required
						onChange={handleChange}
					/>
					<label htmlFor="confirmPassword">ยืนยันรหัสผ่าน</label>
				</div>

				<button type="submit" className="btn btn-warning" disabled={isSubmitting}>
					สมัครสมาชิก
				</button>
				<div className="auth-suggestion">
					มีบัญชีผู้ใช้งานอยู่แล้ว?&nbsp;
					<Link to="/sign-in" className="link">
						เข้าสู่ระบบ
					</Link>
				</div>
			</form>
		</div>
	)
}

export default SignUp
