import dayjs from 'dayjs'
import React, { Fragment, useEffect, useState } from 'react'
import { useAccount } from '../../../hooks/useAccount'
import { useReferral } from '../../../hooks/useReferral'
import CurrentCommission from './CurrentCommission'
import TotalCommission from './TotalCommission'
import TotalRegisteredUsers from './TotalRegisteredUsers'

const SummarySection = () => {
	const { account } = useAccount()
	const { referral, requestReferralSummary } = useReferral()

	const [start, setStart] = useState(dayjs().startOf('month').format('YYYY-MM-DD'))
	const [end, setEnd] = useState(dayjs().endOf('month').format('YYYY-MM-DD'))
	const refetchReferralSummary = () => {
		requestReferralSummary({ start, end })
	}

	useEffect(() => {
		if (!referral.loading && referral.lastUpdated === 0) {
			requestReferralSummary({
				start,
				end,
			})
		}
	}, [referral, start, end, requestReferralSummary])

	if (!account.data || !referral.summary) {
		return null
	}
	return (
		<Fragment>
			<div className="row align-items-end mb-4">
				<div className="col-md-3 col-6">
					<label htmlFor="start">จาก</label>
					<input
						type="date"
						name="start"
						id="start"
						className="form-control"
						defaultValue={start}
						required
						onChange={(e) => setStart(e.currentTarget.value)}
					/>
				</div>
				<div className="col-md-3 col-6">
					<label htmlFor="end">ถึง</label>
					<input
						type="date"
						name="end"
						id="end"
						className="form-control"
						defaultValue={end}
						required
						onChange={(e) => setEnd(e.currentTarget.value)}
					/>
				</div>
				<div className="col-md-3 mt-sm-0 mt-3">
					<button
						type="button"
						className="btn btn-warning"
						disabled={referral.loading}
						onClick={refetchReferralSummary}
					>
						ค้นหา
					</button>
				</div>
			</div>
			<div className="row">
				<div className="col-md-4 mb-sm-0 mb-3">
					<TotalRegisteredUsers data={referral.summary.registeredUserCount} />
				</div>
				<div className="col-md-4 mb-sm-0 mb-3">
					<TotalCommission data={referral.summary.commission} />
				</div>
				<div className="col-md-4 mb-sm-0 mb-3">
					<CurrentCommission data={account.data.referral.balance} />
				</div>
			</div>
		</Fragment>
	)
}

export default SummarySection
