import { request } from '..'
import { Account, Notification } from './types'

export const getAccountInfo = () =>
	request<Account>({
		method: 'GET',
		url: '/account',
	})

export const getNotifications = () =>
	request<Notification[]>({
		method: 'GET',
		url: '/account/notifications',
	})
