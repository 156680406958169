import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { ReferralLink } from '../../api/referralLinks/types'
import { signOut } from '../account/account.actions'
import { getReferralLinksActions } from './referralLinks.actions'

export interface State {
	loading: boolean
	links: ReferralLink[]
	lastUpdated: number
}
export const defaultState: State = {
	loading: false,
	links: [],
	lastUpdated: 0,
}

export const reducer = reducerWithInitialState(defaultState)
	.case(getReferralLinksActions.started, (state) => ({
		...state,
		loading: true,
	}))
	.case(getReferralLinksActions.done, (state, payload) => ({
		...state,
		loading: false,
		links: payload.result,
		lastUpdated: Date.now(),
	}))
	.case(getReferralLinksActions.failed, (state) => ({
		...state,
		loading: false,
	}))
	.case(signOut, () => defaultState)
	.default((state) => state)
