import React from 'react'
import { useSubscriptions } from '../../../hooks/useSubscriptions'

const SubscriptionList = () => {
	const { subscriptions, subscriptionsUsage } = useSubscriptions()

	const renderTableContent = () => {
		if (!subscriptions || subscriptions.length <= 0) {
			return (
				<tr>
					<td colSpan={2} className="text-center">
						คุณไม่มีบริการรายเดือนในขณะนี้
					</td>
				</tr>
			)
		}
		return subscriptions.map((row) => {
			const usage = subscriptionsUsage?.find((v) => v.id === row.rushhID)

			return (
				<tr key={`subscription-${row.id}`} className="subscription-tr">
					<td className="subscription-td-details">
						<a href={`https://facebook.com/${row.profileID}`} target="_blank" rel="noreferrer">
							<h5>
								<i className="fab fa-facebook" />
								&nbsp; {row.profileName}
							</h5>
						</a>
						<h6>
							ไลฟ์วันนี้: <b>{usage?.count || 0}/5</b> ครั้ง
						</h6>
						<h6>วันหมดอายุ: {row.expireAt}</h6>
					</td>
					<td className="text-center">
						<span className="badge bg-warning text-dark">
							<i className="fad fa-eye" /> &nbsp; {row.views}
						</span>
					</td>
				</tr>
			)
		})
	}

	if (!subscriptions) {
		return null
	}
	return (
		<section className="page-section">
			<div className="page-section-header">
				<h3>บริการรายเดือนของคุณ</h3>
			</div>

			<div className="page-section-content">
				<div className="table-responsive">
					<table className="table">
						<thead className="table-light">
							<tr>
								<th>โปรไฟล์</th>
								<th></th>
							</tr>
						</thead>
						<tbody>{renderTableContent()}</tbody>
					</table>
				</div>

				<span className="subscription-remark text-warning text-sm">
					* สามารถไลฟ์ได้สูงสุด <b>5</b> ไลฟ์ต่อวัน (ไม่จำกัดเวลาไลฟ์)
				</span>
			</div>
		</section>
	)
}

export default SubscriptionList
