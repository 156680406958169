import { request } from '..'
import { APIResponse } from '../types'
import { AddSubscriptionPayload, Subscription, SubscriptionOption, SubscriptionTask, SubscriptionUsage } from './types'

export const getSubscriptionOptions = () =>
	request<SubscriptionOption[]>({
		method: 'GET',
		url: '/subscriptions/options',
	})

export const addSubscription = (payload: AddSubscriptionPayload) =>
	request<APIResponse>({
		method: 'POST',
		url: '/subscriptions',
		data: payload,
	})

export const getSubscriptions = () =>
	request<Subscription[]>({
		method: 'GET',
		url: '/subscriptions',
	})

export const getSubscriptionsTasks = () =>
	request<SubscriptionTask[]>({
		method: 'GET',
		url: '/subscriptions/tasks',
	})

export const getSubscriptionsUsage = () =>
	request<SubscriptionUsage[]>({
		method: 'GET',
		url: '/subscriptions/usage',
	})
