import { request } from '..'
import { APIResponse } from '../types'
import { GetReferralSummaryParams, ReferralSummary, ReferralWithdraw } from './types'

export const getReferralSummary = (params: GetReferralSummaryParams) =>
	request<ReferralSummary>({
		method: 'GET',
		url: '/referral/summary',
		params: params,
	})

export interface CreateReferralWithdrawPayload {
	amount: number
	bankCode: string
	bankNumber: string
}
export const createReferralWithdraw = (payload: CreateReferralWithdrawPayload) =>
	request<APIResponse>({
		method: 'POST',
		url: '/referral/withdraws',
		data: payload,
	})

export const getReferralWithdraws = () =>
	request<ReferralWithdraw[]>({
		method: 'GET',
		url: '/referral/withdraws',
	})
