import React, { FC } from 'react'
import { useReferralWithdraws } from '../../../hooks/useReferralWithdraws'
import { getBankName } from '../../../utilities/bank'
import { format } from '../../../utilities/number'

interface ReferralWithdrawStatusProps {
	status: 'pending' | 'confirmed' | 'rejected'
}
const ReferralWithdrawStatus: FC<ReferralWithdrawStatusProps> = ({ status }) => {
	if (status === 'pending') {
		return <span className="badge bg-dark">รอตรวจสอบ</span>
	}
	if (status === 'confirmed') {
		return <span className="badge bg-success">รายการสำเร็จ</span>
	}
	return <span className="badge bg-danger">รายการไม่สำเร็จ</span>
}

interface ReferralWithdrawProps {
	openCreateWithdrawDialog: () => void
}
const ReferralWithdraw: FC<ReferralWithdrawProps> = ({ openCreateWithdrawDialog }) => {
	const { referralWithdraws } = useReferralWithdraws()

	const renderTableContent = () => {
		if (!referralWithdraws || referralWithdraws.length <= 0) {
			return (
				<tr>
					<td colSpan={3} className="text-center">
						คุณไม่มีรายการถอนเงิน
					</td>
				</tr>
			)
		}
		return referralWithdraws.map((row) => (
			<tr key={`referral-withdraw.${row.id}`}>
				<td className="withdraw-desc">
					<h5>{getBankName(row.bankCode)}</h5>
					<h5>
						<b>{row.bankNumber}</b>
					</h5>
					<h6>{row.createdAt}</h6>
				</td>
				<td>
					<b>{format(row.amount, 2)}</b> บาท
				</td>
				<td className="text-center">
					<ReferralWithdrawStatus status={row.status} />
				</td>
			</tr>
		))
	}

	return (
		<section className="page-section">
			<div className="page-section-header with-actions">
				<div>
					<h3>รายการถอนยอดคอมมิชชั่น</h3>
					<h6>ประวัติการถอนยอดคอมมิชชั่น (10 รายการล่าสุด)</h6>
				</div>
				<div>
					<button type="button" className="btn btn-warning btn-sm" onClick={openCreateWithdrawDialog}>
						<i className="fad fa-inbox-out" /> &nbsp; ถอนเงิน
					</button>
				</div>
			</div>

			<div className="page-section-content">
				<div className="table-responsive">
					<table className="table">
						<thead className="table-light">
							<tr>
								<th>รายละเอียด</th>
								<th>จำนวนเงิน</th>
								<th>สถานะ</th>
							</tr>
						</thead>
						<tbody>{renderTableContent()}</tbody>
					</table>
				</div>
			</div>
		</section>
	)
}

export default ReferralWithdraw
