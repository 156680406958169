import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import {
	defaultState as defaultAccountState,
	reducer as account,
	State as AccountState,
} from './account/account.reducers'
import {
	defaultState as defaultReferralState,
	reducer as referral,
	State as ReferralState,
} from './referral/referral.reducer'
import {
	defaultState as defaultReferralLinksState,
	reducer as referralLinks,
	State as ReferralLinksState,
} from './referralLinks/referralLinks.reducer'

const reduxDevTools = (window as any).__REDUX_DEVTOOLS_EXTENSION__

export interface ApplicationState {
	account: AccountState
	referral: ReferralState
	referralLinks: ReferralLinksState
}
export const defaultState: ApplicationState = {
	account: defaultAccountState,
	referral: defaultReferralState,
	referralLinks: defaultReferralLinksState,
}

const reducers = combineReducers<ApplicationState>({
	account,
	referral,
	referralLinks,
})

// ENHANCERS
const enhancers = compose(applyMiddleware(thunk), reduxDevTools ? reduxDevTools() : (f: any) => f)

export default createStore(reducers, defaultState, enhancers)
