import React, { Fragment, useState } from 'react'
import { useSubscriptions } from '../../hooks/useSubscriptions'
import AddSubscriptionDialog from './AddSubscriptionDialog/AddSubscriptionDialog'
import SubscriptionList from './SubscriptionList/SubscriptionList'
import SubscriptionTaskList from './SubscriptionTaskList/SubscriptionTaskList'

const Subscriptions = () => {
	const { subscriptionOptions } = useSubscriptions()

	const [openAddSubscriptionDialog, setOpenAddSubscriptionDialog] = useState(false)

	if (!subscriptionOptions) {
		return null
	}
	return (
		<Fragment>
			<div className="page-content">
				<div className="page-header">
					<div className="page-title">
						<h3>
							<i className="fad fa-calendar-alt" /> &nbsp;บริการรายเดือน
						</h3>
					</div>
					<div className="page-actions">
						<button
							type="button"
							className="btn btn-warning"
							onClick={() => setOpenAddSubscriptionDialog(true)}
						>
							<i className="fad fa-plus" /> &nbsp; เพิ่มบริการรายเดือน
						</button>
					</div>
				</div>

				<div className="row">
					<div className="col-md-6">
						<SubscriptionList />
					</div>
					<div className="col-md-6">
						<SubscriptionTaskList />
					</div>
				</div>
			</div>

			{openAddSubscriptionDialog && <AddSubscriptionDialog onClose={() => setOpenAddSubscriptionDialog(false)} />}
		</Fragment>
	)
}

export default Subscriptions
