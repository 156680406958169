import React from 'react'
import { useSubscriptions } from '../../../hooks/useSubscriptions'

const SubscriptionTaskList = () => {
	const { subscriptionsTasks } = useSubscriptions()

	const renderTableContent = () => {
		if (!subscriptionsTasks || subscriptionsTasks.length <= 0) {
			return (
				<tr>
					<td colSpan={2} className="text-center">
						คุณไม่มีรายการไลฟ์
					</td>
				</tr>
			)
		}
		return subscriptionsTasks.map((task) => (
			<tr key={`task-${task.id}`} className="task-list-tr">
				<td className="task-list-details">
					<h5>
						<a href={`https://facebook.com/${task.videoID}`} target="_blank" rel="noreferrer">
							{task.description}
						</a>
					</h5>
					<h6>{task.createdAt}</h6>
				</td>
				<td className="text-center">
					{task.status === 'LIVE' ? (
						<div className="badge bg-danger">
							<i className="fad fa-eye" /> &nbsp; {task.currentViews}/{task.views}
						</div>
					) : (
						<div className="badge bg-secondary">
							<i className="fad fa-eye" /> &nbsp; {task.views}
						</div>
					)}
				</td>
			</tr>
		))
	}

	return (
		<section className="page-section">
			<div className="page-section-header">
				<h3>ประวัติการไลฟ์ย้อนหลัง</h3>
			</div>

			<div className="page-section-content">
				<div className="table-responsive">
					<table className="table">
						<thead className="table-light">
							<tr>
								<th>รายละเอียด</th>
								<th></th>
							</tr>
						</thead>
						<tbody>{renderTableContent()}</tbody>
					</table>
				</div>
			</div>
		</section>
	)
}

export default SubscriptionTaskList
