import { useDispatch, useSelector } from 'react-redux'
import { GetReferralSummaryParams } from '../api/referral/types'
import { ApplicationState } from '../store'
import { getReferralSummary } from '../store/referral/referral.requests'
import { ThunkDispatch } from '../store/types'

export const useReferral = () => {
	const dispatch: ThunkDispatch = useDispatch()
	const referral = useSelector((state: ApplicationState) => state.referral)

	const requestReferralSummary = (params: GetReferralSummaryParams) => dispatch(getReferralSummary(params))

	return {
		referral,
		requestReferralSummary,
	}
}
