export const getBankName = (bankCode: string): string => {
	switch (bankCode) {
		case '002': {
			return 'ธนาคารกรุงเทพ (BBL)'
		}
		case '004': {
			return 'ธนาคารกสิกรไทย (KBANK)'
		}
		case '006': {
			return 'ธนาคารกรุงไทย (KTB)'
		}
		case '011': {
			return 'ธนาคารทหารไทยธนชาต (TTB)'
		}
		case '014': {
			return 'ธนาคารไทยพาณิชย์ (SCB)'
		}
		case '022': {
			return 'ธนาคารซีไอเอ็มบี (CIMB)'
		}
		case '025': {
			return 'ธนาคารกรุงศรีอยุธยา (BAY)'
		}
		case '030': {
			return 'ธนาคารออมสิน (GSB)'
		}
		default: {
			return bankCode
		}
	}
}
