import { getReferralSummary as _getReferralSummary } from '../../api/referral/refferal'
import { GetReferralSummaryParams, ReferralSummary } from '../../api/referral/types'
import { APIError } from '../../api/types'
import { createRequestThunk } from '../store.helpers'
import { getReferralSummaryActions } from './referral.actions'

export const getReferralSummary = createRequestThunk<GetReferralSummaryParams, ReferralSummary, APIError>(
	getReferralSummaryActions,
	_getReferralSummary
)
