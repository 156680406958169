import React, { useEffect, useState } from 'react'
import { useAccount } from '../../hooks/useAccount'
import { useReferralLinks } from '../../hooks/useReferralLinks'
import CreateReferralLinkDialog from './ReferralLinks/CreateReferralLinkDialog'
import ReferralLinks from './ReferralLinks/ReferralLinks'
import CreateReferralWithdrawDialog from './ReferralWithdraw/CreateReferralWithdrawDialog'
import ReferralWithdraw from './ReferralWithdraw/ReferralWithdraw'
import SummarySection from './SummarySection/SummarySection'

const Referral = () => {
	const { account } = useAccount()
	const { referralLinks, requestReferralLinks } = useReferralLinks()
	useEffect(() => {
		if (!referralLinks.loading && referralLinks.lastUpdated === 0) {
			requestReferralLinks()
		}
	}, [referralLinks, requestReferralLinks])

	// Create referral-link dialog
	const [openCreateLinkDialog, setOpenCreateLinkDialog] = useState(false)

	// Create referral-withdraw dialog
	const [openCreateWithdrawDialog, setOpenCreateWithdrawDialog] = useState(false)

	if (!account.data) {
		return null
	}
	return (
		<div className="page-content">
			<div className="page-header">
				<div className="page-title">
					<h3>
						<i className="fad fa-hand-holding-usd" /> &nbsp;แนะนำเพื่อน
					</h3>
				</div>
				<div className="page-actions">
					<button type="button" className="btn btn-warning" onClick={() => setOpenCreateLinkDialog(true)}>
						<i className="fad fa-plus" /> &nbsp; สร้างลิงก์แนะนำเพื่อน
					</button>
				</div>
			</div>

			{/* SummarySection */}
			<div className="mb-4">
				<SummarySection />
			</div>

			<div className="row mt-5">
				<div className="col-md-6">
					{/* ReferralLinks */}
					<ReferralLinks account={account.data} />
				</div>
				<div className="col-md-6">
					<ReferralWithdraw openCreateWithdrawDialog={() => setOpenCreateWithdrawDialog(true)} />
				</div>
			</div>

			{/* CreateReferralLinkDialog */}
			{openCreateLinkDialog && (
				<CreateReferralLinkDialog open={openCreateLinkDialog} onClose={() => setOpenCreateLinkDialog(false)} />
			)}

			{/* CreateReferralWithdraw */}
			{openCreateWithdrawDialog && <CreateReferralWithdrawDialog onClose={() => setOpenCreateWithdrawDialog(false)} />}
		</div>
	)
}

export default Referral
