const localStorageCache: Record<string, string | null> = {}
export const getStorage = (key: string) => {
	if (localStorageCache[key]) {
		return localStorageCache[key]
	}

	const item = window.localStorage.getItem(key)
	localStorageCache[key] = item
	return item
}

export const setStorage = (key: string, value: string) => {
	localStorageCache[key] = value
	return window.localStorage.setItem(key, value)
}
