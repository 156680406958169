import firebase from 'firebase/app'
import 'firebase/auth'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../store'
import { signOut as _signOut } from '../store/account/account.actions'
import { getAccount } from '../store/account/account.requests'
import { ThunkDispatch } from '../store/types'

export const useAccount = () => {
	const dispatch: ThunkDispatch = useDispatch()
	const account = useSelector((state: ApplicationState) => state.account)

	const requestAccount = () => dispatch(getAccount())
	const signOut = async () => {
		try {
			await firebase.auth().signOut()
		} catch (error) {
			console.error(error)
		} finally {
			dispatch(_signOut())
		}
	}

	return {
		account,
		requestAccount,
		signOut,
	}
}
