import React from 'react'
import { useTopupTransactions } from '../../hooks/useTopupTransactions'
import { format } from '../../utilities/number'

const TopupTransactions = () => {
	const { data } = useTopupTransactions()

	const renderTableContent = () => {
		if (!data || data.length <= 0) {
			return (
				<tr>
					<td colSpan={2} className="text-center">
						คุณไม่มีรายการเติมเงิน
					</td>
				</tr>
			)
		}
		return data.map((tx) => (
			<tr key={`topup-${tx.id}`} className="topup-tx-tr">
				<td className="topup-tx-details">
					<h5>{tx.remark}</h5>
					<h6>{tx.createdAt}</h6>
				</td>
				<td>
					<b>{format(tx.amount, 2)}</b> บาท
				</td>
			</tr>
		))
	}
	return (
		<section className="page-section">
			<div className="page-section-header">
				<h3>รายการเติมเงิน</h3>
				<h6>ประวัติการเติมเงินย้อนหลัง (10 รายการล่าสุด)</h6>
			</div>

			<div className="page-section-content">
				<div className="table-responsive">
					<table className="table">
						<thead className="table-light">
							<tr>
								<th>รายละเอียด</th>
								<th>จำนวนเงิน</th>
							</tr>
						</thead>
						<tbody>{renderTableContent()}</tbody>
					</table>
				</div>
			</div>
		</section>
	)
}

export default TopupTransactions
