import React, { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { Account } from '../../api/account/types'
import { format } from '../../utilities/number'

interface NavbarProps {
	account: Account

	signOut: () => void
}
const Navbar: FC<NavbarProps> = ({ account, signOut }) => (
	<nav className="navbar navbar-expand-lg navbar-dark bg-dark">
		<div className="container">
			<NavLink to="/dashboard" className="navbar-brand">
				<i className="fad fa-podcast" /> &nbsp; UPLIVE-TH
			</NavLink>
			<button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarContent">
				<span className="navbar-toggler-icon" />
			</button>
			<div className="collapse navbar-collapse" id="navbarContent">
				<ul className="navbar-nav me-auto">
					<li className="nav-item">
						<NavLink to="/dashboard" className="nav-link">
							<i className="fad fa-home" /> &nbsp; หน้าแรก
						</NavLink>
					</li>
					<li className="nav-item">
						<NavLink to="/subscriptions" className="nav-link">
							<i className="fad fa-calendar-alt" /> &nbsp; บริการรายเดือน
						</NavLink>
					</li>
					<li className="nav-item">
						<NavLink to="/topup" className="nav-link">
							<i className="fad fa-usd-circle" /> &nbsp; เติมเงิน
						</NavLink>
					</li>
					<li className="nav-item">
						<NavLink to="/referral" className="nav-link">
							<i className="fad fa-hand-holding-usd" /> &nbsp; แนะนำเพื่อน
						</NavLink>
					</li>
					<li className="nav-item">
						<a href="https://lin.ee/UQsudLi" target="_blank" rel="noreferrer" className="nav-link">
							<i className="fab fa-line" /> &nbsp; ติดต่อเรา
						</a>
					</li>
				</ul>

				<div className="ml-auto">
					<ul className="navbar-nav me-auto">
						<li className="nav-item">
							<span className="nav-link">
								<i className="fad fa-coins" /> &nbsp; ยอดคงเหลือ&nbsp;
								<b>{format(account.balance, 2)}</b> บาท
							</span>
						</li>
						<li className="nav-item">
							<span className="nav-link" onClick={signOut}>
								<i className="fad fa-sign-out" /> &nbsp; ออกจากระบบ
							</span>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</nav>
)

export default Navbar
