import { getReferralLinks as _getReferralLinks } from '../../api/referralLinks/referralLinks'
import { ReferralLink } from '../../api/referralLinks/types'
import { APIError } from '../../api/types'
import { createRequestThunk } from '../store.helpers'
import { getReferralLinksActions } from './referralLinks.actions'

export const getReferralLinks = createRequestThunk<void, ReferralLink[], APIError>(
	getReferralLinksActions,
	_getReferralLinks
)
