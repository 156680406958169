export const generateQRCodeLink = (data: string) => {
	const endPoint = process.env.REACT_APP_API_ENDPOINT || 'http://localhost:8081/v1'
	return endPoint + '/generate-qr?data=' + data
}

export const getStripeKey = () => {
	return (
		process.env.REACT_APP_STRIPE_KEY ||
		'pk_test_51QJVYAEtVYJDHofsi9WzfrmSSZ4EWLb0s2JOG7zI0Vt892j5RfpfiwtjdhuYnU9W5qx6zULuxjM8PqdOTjwWHVHO00OsXqulA9'
	)
}
